import { Col, Row, Container, Button } from "react-bootstrap";
import { useSpring, animated, easings } from "react-spring";
import PartnersInMissionLogo from "../graphics/PartnersInMissionLogo";

export default function PartnersOnMission() {

    document.title = "COMPACT Partners On Mission";

    const titleOne = useSpring({
        to: { opacity: 1, y: 0 },
        from: { opacity: 0, y: -50 },
        pause: 2000,
        // loop: true,
        delay: 500,
        config: {
          duration: 2000,
          mass: 500,
          friction: 500,
          easing: easings.easeInOutQuad,
        },
      });
      const titleTwo = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: 2000,
        // loop: true,
        delay: 2500,
        config: {
          duration: 2000,
          mass: 500,
          friction: 500,
          easing: easings.easeInOutQuad,
        },
      });
    //   const titleThree = useSpring({
    //     from: { opacity: 0 },
    //     to: { opacity: 1 },
    //     pause: 2000,
    //     // loop: true,
    //     delay: 3500,
    //     config: {
    //       duration: 2000,
    //       mass: 500,
    //       friction: 500,
    //       easing: easings.easeInOutQuad,
    //     },
    //   });
    return (
    <>

<Container fluid className="photoblue m-0">
        <Container>
          <Row className="text-center">
            <Col lg={6} md={12} className="mt-3 d-flex align-items-center">
              <div>
                <animated.h1 style={titleOne} className="h1 fw-bold">
                  Partners On Mission
                </animated.h1>

                <animated.p style={titleTwo} className="lead">
                  Since 1944 COMP
                  <span className="act">ACT</span>
                   {""} Family Services has served the most vulnerable children and families who have been impacted by foster care and adoption.
                </animated.p>
                {/* <animated.p style={titleThree} className="lead">
                  asdf
                </animated.p> */}
              </div>
            </Col>

            <Col lg={6} md={12}>
            <PartnersInMissionLogo />
              {/* <Image
                fluid
                src="https://compact.family/images/0758.jpg"
                alt="Child representative"
                layout="intrinsic"
                width="600"
                height="519"
              /> */}
            </Col>
          </Row>
        </Container>
      </Container>

<Container fluid className="lead bg-blue text-white p-5">
    <Container>

<p>Through ministries like
Hillcrest Children’s Home and Highlands Maternity, COMP
                  <span className="act">ACT</span>
                   {""} has served thousands of
children as the premier ministry for foster care in the Assemblies of God for decades.
</p>
<p className="h4">Our commitment to “settling the lonely into families” is unwavering. (Ps. 68:6)</p>

    </Container>
</Container>


<Container className="py-5">
<p>COMP
                  <span className="act">ACT</span>
                   {""} has long dreamed of the day when our effectiveness would be multiplied
through partnerships with local churches everywhere; when God’s people would be
resourced, equipped, and compelled to reach the most vulnerable in their own
backyards. There is nothing like the Church when the Church is being the Church!</p>

<p>
Introducing COMP
                  <span className="act">ACT</span>
                   {""} Partners on Mission. This initiative of COMPassionate ACTion
aims to build a coalition of churches and individuals nationwide who will embrace a joint
calling to redeem children and families in literally every community. We are convinced
that together we can amplify our effectiveness and reach the critical mission field of the
family. With over 400,000 children in foster care each day in America, we envision a
day when the Assemblies of God is doing our part to care for these children and their
families. With the Assemblies of God making up 5% of the nation’s Evangelical church,
our initial goal is to care for 20,000 foster children in our AG churches, which represents
5% of the daily need. 
</p>
<p>When you commit to being a COMP
                  <span className="act">ACT</span>
                   {""} Partner on Mission, you are adding your voice
to speak up for vulnerable children and families. Your partnership enables us to
continue equipping churches everywhere with effective tools and resources to tackle
this crisis at the local levels. At the same time, your partnership also adds fuel to the
growing redemptive revolution that is happening nationwide!</p>

</Container>

<Container className="mb-5">
    <p className="h3 text-center mb-5 blue">Will you be a COMP
                  <span className="act">ACT</span>
                   {""} Partner on Mission?</p>

<Row className="d-flex align-items-stretch">
    <Col md={6} className="d-flex">
    <div className="bg-blue p-5 text-white h-100">

    <p className="text-center h3">For Churches</p>
    <p className="lead text-center">Suggested minimum commitment: $50/month</p>
    <p className="fw-bold">Our gift to your church:</p>
    <ul>
        <li>1 complimentary clinic registration for
COMP
                  <span className="act">ACT</span>
                   {""}’s Promise 686 ministry
model.</li>
<li>Access to PromiseServes.</li>
<li>Individual coaching and support to
implement the training.</li>
<li>Access to a library of valuable training
videos for foster and adoptive families.</li>
<li>Promotional materials.</li>
<li>BOGO registration for the AG Foster
Care Network Conference. ($139 value)</li>
<li>A welcome packet that includes a
branded t-shirt and a book.</li>
<li>10% discount on merchandise.</li>
    </ul>
    <div className="text-center">

<Button
    variant="light"
    className="text-black"
    type="submit"
    rel="noopener noreferrer"
    target="_blank"
    href="https://giving.ag.org/donate/compact-family-services/COMPACTspecialprojects"
    // href="https://giving.ag.org/donate/compact-family-services?fundid=38231"
    >
Donate as a church

      </Button>
        </div>
    </div>
    </Col>
    <Col md={6} className="d-flex">
    <div className="bg-purple p-5 text-white h-100">

    <p className="text-center h3">For Individuals</p>
    <p className="lead text-center">Suggested minimum commitment: $25/month</p>
    <p className="fw-bold">Our gift to you:</p>
    <ul>
        <li>Promotional materials.</li>
<li>Access to a library of valuable training
videos for foster and adoptive families.</li>
<li>Special pricing on registration for the
AGFCN conference.</li>
<li>Access to a library of valuable training
videos for foster and adoptive families.</li>
<li>A welcome packet that includes a
branded t-shirt and a book.</li>
<li>Promise Tree Book</li>
<li>A welcome packet that includes a
branded t-shirt and a book.</li>
<li>10% discount on merchandise.</li>
    </ul>
    <div className="text-center">

<Button
    variant="light"
    className="text-black"
    type="submit"
    rel="noopener noreferrer"
    target="_blank"
    href="https://giving.ag.org/donate/compact-family-services/COMPACTspecialprojects"
    >
Donate as an individual

      </Button>
        </div>
    </div>
    </Col>
</Row>

</Container>



    </>
)
}