import { useState } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from "@apollo/client";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

const client = new ApolloClient({
    uri: 'https://www.compact.family/wpapi/graphql/',
    cache: new InMemoryCache()
});

const StateContent = ({ stateName, contentType }) => {
    // Generate the slug based on the contentType
    const baseSlug = stateName.toLowerCase().replace(/ /g, '-');
    const slug = contentType === "Adoption from Foster Care" ? `${baseSlug}-aff` : baseSlug;

    // Set the correct category based on the content type
    const category = contentType === "State Adoption Info" ? "highlands-map-states" : "highlands-map-adopt-from-foster";

    const QUERY_STATE = gql`
        query AllPosts($category: String!, $first: Int!) {
            posts(where: {categoryName: $category}, first: $first) {
                edges {
                    node {
                        date
                        title
                        id
                        content
                        slug
                    }
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(QUERY_STATE, { variables: { category, first: 100 } });

    if (loading) return "Loading...";
    if (error) return `Error! ${error.message}`;

    const post = data?.posts?.edges?.find(edge => edge.node.slug === slug);
    if (!post) {
        return "Cannot find content. Please let us know through the contact form under Contact Us. Thank you.";
    }

    const transform = node => {
        if (node.type === 'tag' && node.name === 'a') {
            node.attribs.target = '_blank'; // This will make links open in a new window
        }
    };

    return (
        <div>{ReactHtmlParser(post.node.content, { transform })}</div>
    );
};

const StateInfoModal2 = ({ stateName, contentType, onClose }) => {
    const [show, setShow] = useState(true);

    const handleHide = () => {
        setShow(false);
        onClose();
    };

    function capitalizeWords(string) {
        return string.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    return (
        <ApolloProvider client={client}>
            <Modal show={show} onHide={handleHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{capitalizeWords(stateName)}</Modal.Title>  
                </Modal.Header>
                <Modal.Body>
                    <StateContent stateName={stateName} contentType={contentType} />
                </Modal.Body>
            </Modal>
        </ApolloProvider>
    );
};

export default StateInfoModal2;
