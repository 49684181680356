import { CopyToClipboard } from "react-copy-to-clipboard";

import { useState, useEffect } from "react";
import { Container, Card, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { useAuth } from "../../util/AuthContext";
import { db } from "../../util/firebase";
// import { FaRegTrashAlt,
//   FaCopy
//  } from "react-icons/fa";
import { onSnapshot, doc, deleteDoc, collection } from "firebase/firestore";

export default function AdminCompacare() {
  // const { currentUser  } = useAuth();
  const [error, setError] = useState("");
  const [messages, setMessages] = useState([]);

  // load all msgs
  useEffect(() => {
    try {
      return onSnapshot(collection(db, "Contact"), (snapshot) => {
        setMessages(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });
    } catch (e) {
      setError(e);
    }
  }, []); // eslint-disable-this-line

  // ! start copy to clipboard

  function handleCopy(id, name, email, message, date) {
    const textToCopy = `${date.toDate().toLocaleDateString("en-US", {
      dateStyle: "long",
    })}\n${name}\n${email}\n${message}`;
    setMessages(
      messages.map((msg) => (msg.id === id ? { ...msg, copied: true } : msg))
    );
    setTimeout(() => {
      setMessages(
        messages.map((msg) => (msg.id === id ? { ...msg, copied: false } : msg))
      );
    }, 2000);
    navigator.clipboard.writeText(textToCopy);
  }
  // ! end copy to clipboard

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Container className="py-3">
        <Link to="/admin">Back to Admin Home</Link>

        <h4>CompaCare Interest Form Messages:</h4>

        {messages &&
          messages.map((msg) => {
            return msg.form === "compacare" ? (
              <Msg key={msg.id} {...msg} />
            ) : null;
          })}

        {/* {messages.length < 1 && 
    <h5>No messages currently from contact us form</h5>
} */}
      </Container>
    </>
  );
}

function Msg({
  id,
  message,
  name,
  date,
  church_address,
  church_name,
  lead_pastor,
  email,
  phone,
  onCopy,
  copied,
}) {
  const deleteMsg = async (e) => {
    e.preventDefault();
    await deleteDoc(doc(db, "Contact", id));
  };

  return (
    <div key={id} className="d-flex flex-column align-items-center">
      <Card style={{ width: "30rem" }} className="bg-light p-3 my-2">
        <p>
          <small>{date.toDate().toDateString()}</small>
        </p>
        <h5>{name}</h5>
        <p>{email}</p>
        <p>phone: {phone}</p>
        {/* <p>id: {id}</p> */}
        <p>Church name: {church_name}</p>
        <p>Pastor name: {lead_pastor}</p>
        <p>Church address: {church_address}</p>
        <p>Message/How did you hear about us: {message}</p>

        <div className="d-flex justify-content-evenly">
          <CopyToClipboard
            text={`Date: ${date
              .toDate()
              .toLocaleDateString("en-US", {
                dateStyle: "long",
              })}\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\nChurch Name: ${church_name}\nPastor Name: ${lead_pastor}\nChurch Address: ${church_address}\nMessage/How did you hear about us: ${message}`}
            onCopy={() =>
              onCopy(
                name,
                email,
                date,
                message,
                church_name,
                lead_pastor,
                church_address
              )
            }
          >
            <Button>Copy Message</Button>
          </CopyToClipboard>

          {/* <Button variant="danger" onClick={() => onDelete(id)}>
            <i className="bi bi-file-x"></i> Delete
          </Button> */}
          {copied && <span style={{ color: "green" }}>Copied!</span>}

          <Button variant="danger" onClick={deleteMsg}>
            <i className="bi bi-file-x"></i> Delete
          </Button>
        </div>
      </Card>
    </div>
  );
}
