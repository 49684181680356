export default function compactLogo({color, secondary}) {
    return (
      <>

<svg className="svgwhite"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 800 476">
<g fill={color}>

  <path d="M737.95 179.13c1.45 0 2.87.37 4.25 1.12 1.38.74 2.46 1.81 3.23 3.19.77 1.39 1.16 2.83 1.16 4.33s-.38 2.92-1.14 4.29-1.82 2.44-3.19 3.2c-1.37.76-2.8 1.14-4.3 1.14s-2.93-.38-4.3-1.14-2.44-1.83-3.2-3.2c-.76-1.37-1.15-2.81-1.15-4.29s.39-2.95 1.16-4.33c.78-1.38 1.85-2.45 3.24-3.19 1.38-.75 2.8-1.12 4.25-1.12Zm0 1.43c-1.21 0-2.4.31-3.54.93-1.15.62-2.05 1.51-2.7 2.67-.65 1.16-.97 2.36-.97 3.61s.32 2.44.96 3.58c.64 1.14 1.53 2.03 2.67 2.67 1.14.64 2.34.96 3.58.96s2.44-.32 3.58-.96c1.14-.64 2.03-1.53 2.67-2.67.63-1.14.95-2.33.95-3.58s-.32-2.46-.97-3.61c-.64-1.16-1.54-2.05-2.7-2.67-1.15-.62-2.33-.93-3.54-.93Zm-3.79 11.99v-9.3h3.19c1.09 0 1.88.09 2.37.26s.88.47 1.17.9c.29.43.44.88.44 1.36 0 .68-.24 1.27-.73 1.78s-1.13.79-1.93.85c.33.14.59.3.79.49.37.37.83.98 1.37 1.84l1.13 1.82h-1.83l-.82-1.47c-.65-1.15-1.17-1.87-1.57-2.17-.28-.21-.68-.32-1.2-.32h-.88v3.95h-1.5Zm1.5-5.23h1.82c.87 0 1.46-.13 1.78-.39.32-.26.48-.6.48-1.03 0-.27-.08-.52-.23-.74-.15-.22-.36-.38-.63-.49-.27-.11-.77-.16-1.51-.16h-1.71v2.81Z" />
  <path d="M151.46 271.75c-9.75 9.62-22.35 14.09-36.03 14.09-35.21 0-50.11-24.24-50.25-48.89-.14-24.78 15.98-49.98 50.25-49.98 12.87 0 25.06 4.88 34.81 14.49l-11.92 11.51c-6.23-6.09-14.63-8.94-22.89-8.94-22.89 0-32.77 17.07-32.64 32.91.14 15.71 9.21 32.1 32.64 32.1 8.26 0 17.61-3.39 23.84-9.62l12.19 12.32Zm97.18-34.94c-.27 24.51-15.31 49.03-48.62 49.03s-48.76-23.97-48.76-48.89 15.98-49.98 48.76-49.98 48.89 25.06 48.62 49.84Zm-79.77.41c.41 15.58 8.8 32.24 31.15 32.24s30.75-16.8 31.02-32.37c.27-15.98-8.67-33.86-31.02-33.86s-31.56 18.01-31.15 33.99ZM338.37 215l-30.88 41.17h-3.52l-30.2-41.31v68.94h-17.88v-94.81h20.59l29.66 40.77 29.66-40.77h20.45v94.81h-17.88V215Zm73.35 40.77H384.9v27.9h-17.88v-94.81c14.9 0 29.8-.14 44.69-.14 46.32 0 46.46 67.04 0 67.04Zm-26.82-16.39h26.82c22.89 0 22.75-33.59 0-33.59H384.9v33.59Z"/>
</g>
<g fill={secondary}>

  <path d="M507.92 268.09h-42.39l-8.71 15.58h-28.44l57.64-94.81h27.09l27.28 94.81h-28.71l-3.75-15.58Zm-12.73-52.68-17 30.2h24.24l-7.24-30.2Zm127.37 55.94c-12.38 10.56-26.05 14.76-40.41 14.76-37.25 0-48-23.43-44.03-49.16 3.75-24.38 24.26-50.25 59.88-50.25 13.68 0 25.25 4.74 34.28 15.17l-19.11 16.25c-5.74-6.5-12.5-8.26-18.86-8.26-18.28 0-27.84 14.9-29.67 27.22-1.83 12.32 2.45 26.27 21.14 26.27 6.37 0 16.01-2.71 23.88-9.61l12.9 17.61Zm37.64-60.41h-27.63l3.52-22.08h82.08l-3.52 22.08h-27.76l-11.6 72.73h-26.68l11.6-72.73ZM395.83 64.19c-54.09 0-103 21.91-138.45 57.34-14.72 14.73-27.1 31.78-36.54 50.57h45.6c28.15-41.36 75.59-68.53 129.4-68.53s101.24 27.17 129.38 68.53h45.62c-9.45-18.78-21.82-35.84-36.55-50.57-35.44-35.44-84.37-57.34-138.45-57.34Zm0 358.52c-83.17 0-154.92-48.76-188.28-119.25H71.54l5.34 8.62 99.89 5.5C215.48 399.73 299 456.6 395.84 456.6s180.35-56.87 219.06-139.02l99.89-5.5 5.34-8.62H584.1c-33.35 70.49-105.11 119.25-188.27 119.25Z" />
  <path d="M395.83 51.79c83.59 0 155.64 49.25 188.77 120.31h135.53l-5.34-8.62-99.4-5.47C576.91 75.29 493.09 17.92 395.84 17.92S214.75 75.29 176.27 158.01l-99.38 5.47-5.34 8.62h135.52c33.14-71.06 105.2-120.31 188.78-120.31Zm0 358.54c54.09 0 103.01-21.91 138.45-57.36 14.46-14.45 26.66-31.14 36.03-49.51h-45.83c-28.24 40.76-75.32 67.48-128.65 67.48s-100.42-26.72-128.66-67.48h-45.83c9.38 18.37 21.57 35.06 36.03 49.51 35.46 35.45 84.36 57.35 138.45 57.36Z" />
</g>
</svg>
       
      </>
    );
  }
  