import { Container } from "react-bootstrap";

import Continuum from "../components/Continuum";
// import { useState, useEffect } from "react";

// Current date and time
const currentDate = new Date();

// Function to calculate the number of leap years since 1944
function countLeapYears(year) {
  let leapYears = 0;
  for (let y = 1944; y <= year; y++) {
      if ((y % 4 === 0 && y % 100 !== 0) || (y % 400 === 0)) {
          leapYears++;
      }
  }
  return leapYears;
}

// Current year
const currentYear = new Date().getFullYear();

// Total hours since 1944 including leap years
const hoursSince1944 = ((currentYear - 1944) * 8760) + countLeapYears(currentYear) * 24;
const formattedHours = hoursSince1944.toLocaleString();

// Assuming the children helped calculation remains the same
const childrenHelped = (currentYear - 1944) * 120;
const formattedChildren = childrenHelped.toLocaleString();

// const hoursSince1944 = (new Date().getFullYear() - 1944) * 8760;
// const formattedHours = hoursSince1944.toLocaleString();
// const childrenHelped = (new Date().getFullYear() - 1944) * 120;
// const formattedChildren = childrenHelped.toLocaleString();

const WelcomePage = () => {
  document.title = "Welcome to COMPACT";

  // month names no longer used. First sentence

  // let monthNumber = new Date().getMonth();
  // let monthNames = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  // let monthName = monthNames[monthNumber];

  
  return (
    <>
      <Container className="my-4">
        <p className="display-5 fw-bold blue text-center">
          COMP
          <span className="act">ACT</span> Family Services,
          <br />
          {new Date().getFullYear() - 1944} {""}
          Years and growing
        </p>

        <p className="lead">

          September 22, {" "}
          {currentYear} marks {currentYear - 1944}{" "}
          
          years of continuous, uninterrupted, around-the-clock ministry to
          vulnerable children and families from this ministry first known as
          National Children’s Home of the Assemblies of God and later as
          Hillcrest Children’s Home.
        </p>
       
<p className="text-center lh-sm fs-5 mt-4">  As of right now at {currentDate.toLocaleString()},</p>
        <p className="text-center fw-bold pb-3 fs-3">
        that is {""}
          <strong className="blue ">

{formattedHours}
          </strong>{" "}
          hours of nonstop ministry!
        </p>

        <p>
          That first mission has grown into a broad continuum of ministries
          today known as COMP
          <span className="act">ACT</span>. COMP
          <span className="act">ACT</span> is a compound word representing
          compassionate action. COMP
          <span className="act">ACT</span> is passionately committed to
          redeeming vulnerable children and families, including some of today’s
          most vulnerable. We are committed to grow as far as the Holy Spirit
          leads and provides to meet the need.
        </p>

        <Continuum />

        <p>
          Since 1944, over{" "}
          {formattedChildren}{" "}
          children and youth have received the Christ-centered redeeming love of
          this ministry, many for years on end. More than 3,000 children have
          been adopted. Each has a name. Each is precious to God. Many have
          their names written in the Lambs Book of Life
          <small>
            <em> (Rev. 21:27)</em>
          </small>
          .
        </p>

        <p>
          Every act of compassionate action unto the Lord for one of these is
          not an end in itself. Each story grows. Some transcending from
          generation to generation. Good seed in good soil can grow a
          hundred-fold
          <small>
            <em> (Mt. 13:8)</em>
          </small>
          . It is true of God’s Word, the sincerest of gifts and the people of
          this ministry
          <small>
            <em> (John 4:34-38)</em>
          </small>
          . One sows, another waters and God brings the increase
          <small>
            <em> (1 Cor. 3:6)</em>
          </small>
          !
        </p>
        <h5>
          The next decade is pivotal. I invite you to believe and partner with
          us to the glory of God!
        </h5>
        {/* <p className="h4">Grow With Us</p> */}
      </Container>
    </>
  );
};

export default WelcomePage;
