import { Container, Image, Row, Col } from "react-bootstrap";
import { useSpring, animated, easings } from "react-spring";

const CarePortalPage = () => {
  document.title = "COMPACT CarePortal";

  const titleOne = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -50 },
    pause: 2000,
    // loop: true,
    delay: 500,
    config: {
      duration: 2000,
      mass: 500,
      friction: 500,
      easing: easings.easeInOutQuad,
    },
  });
  // const titleTwo = useSpring({
  //   from: { opacity: 0 },
  //   to: { opacity: 1 },
  //   pause: 2000,
  //   // loop: true,
  //   delay: 2500,
  //   config: {
  //     duration: 2000,
  //     mass: 500,
  //     friction: 500,
  //     easing: easings.easeInOutQuad,
  //   },
  // });

  return (
    <>
      <Container fluid className="my-0 py-0 photoblue">
        <Container className="text-center">
          <div className="row">
            <div className="col-md-12 col-lg-7 d-flex justify-content-center align-items-center pt-3">
              <div>
                <animated.h1 className="fw-bold lh-1 fs-1" style={titleOne}>
                COMP
          <span className="act">ACT</span> CarePortal Enrollment                 
                </animated.h1>

                {/* <animated.div style={titleTwo}>
                  <p className="mt-4 fs-5 fw-bold">
                    FRIENDS. INDIVIDUALS. NEIGHBORS. DEVOTEES.
                  </p>

                  <p className="mt-4 fs-5">
                    Genogram and a kinship support network assists child
                    placement agencies in locating a foster child's relatives.
                  </p>
                </animated.div> */}

               
              </div>
            </div>
            <div className="col-md-12 col-lg-5">
              <Image
                fluid
                src="https://compact.family/images/0748.jpg"
                width="560"
                height="553"
                Layout="intrinsic"
              />{" "}
            </div>
          </div>
        </Container>
      </Container>

      <Container
        fluid
        className="text-center mb-0 pt-5 pb-2 bg-dark text-white"
      >
        <Container className="mb-5 col-lg-6 col-md-12 text-center offset-lg-3 offset-md-0">

<p className="fs-1">What is CarePortal?</p>

          <p className="fs-4">
          Are you looking for a way to make a positive impact in the life of a child or family in need?
CarePortal connects the local church to families in your community who are in need. Because
every child and every family matter.
          </p>
        </Container>
      </Container>

      <Container className="p-5 p-4">
        <p className="fs-2">How Does CarePortal portal Work?</p>
        <p>
          Through COMP
          <span className="act">ACT</span>'s CarePortal portal platform your church is notified of urgent, real-time
needs of vulnerable children and families in your community. It’s the local church that lets a
family know your church cares about them and is ready to help meet their needs.
        </p>


      </Container>


<Row fluid className="photoblue">

      <Col md={6} className="px-5 py-4 d-flex flex-column align-items-center justify-content-center">
        <p className="fs-2 text-center">Want To Create a Church Account?</p>
        
        <p>

        <a href="https://system.careportal.org/enrollment/church?partnerID=58"
                    target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-primary">
                        Enroll here
                      </button>
                    </a>
                      </p>
       
    
    </Col>
    <Col>
    <Image
                fluid
                src="https://compact.family/images/0517.jpg"
                width="560"
                height="553"
                Layout="intrinsic"
              />{" "}</Col>
                      </Row>

      <Container fluid className="bg-blue py-5 px-5">
        <p className="h3 text-center text-white">How do I donate?</p>
        <p className="text-center">

        <a href="https://giving.ag.org/donate/compact-family-services?fundid=38231"
                    target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-dark">
                       Donate here
                      </button>
                    </a>
                      </p>
      </Container>
    </>
  );
};

export default CarePortalPage;
