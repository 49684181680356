// import {getFirestore, collection, query, where, getDocs } from "firebase/firestore"
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Link } from "react-router-dom";
import { Card, Button, Container, Alert, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";

import "firebase/compat/firestore";

import { db } from "../../util/firebase";
import { onSnapshot, doc, deleteDoc, collection } from "firebase/firestore";

export default function AdminContact() {
  document.title = "CompaCare Admin";
  // const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(false);

  // ! load all msgs
  useEffect(() => {
    try {
      return onSnapshot(collection(db, "Contact"), (snapshot) => {
        setMessages(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });
    } catch (e) {
      setError(e);
    }
  }, []); // eslint-disable-this-line

  // ! start copy to clipboard

  function handleCopy(id, type, ...data) {
    let textToCopy;

    if (type === "contactus") {
      const [name, email, date, message] = data;
      textToCopy = `Date: ${date
        .toDate()
        .toLocaleDateString("en-US", {
          dateStyle: "long",
        })}\nName: ${name}\nEmail: ${email}\nMessage: ${message}`;
    } else if (type === "compacare") {
      const [
        name,
        email,
        date,
        message,
        church_name,
        lead_pastor,
        church_address,
        phone,
      ] = data;
      textToCopy = `Date: ${date
        .toDate()
        .toLocaleDateString("en-US", {
          dateStyle: "long",
        })}\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\nChurch Name: ${church_name}\nPastor Name: ${lead_pastor}\nChurch Address: ${church_address}\nMessage/How did you hear about us: ${message}`;
    }

    setMessages(
      messages.map((msg) => (msg.id === id ? { ...msg, copied: true } : msg))
    );
    setTimeout(() => {
      setMessages(
        messages.map((msg) => (msg.id === id ? { ...msg, copied: false } : msg))
      );
    }, 2000);

    navigator.clipboard.writeText(textToCopy);
  }

  // ! end copy to clipboard

  // function handleDelete(id) {
  //   deleteDoc(doc(db, "Contact", id));
  // }

  return (
    <>
      {error && <Alert variant="warning">{error}</Alert>}
      <Container className="py-3">
        <Link to="/admin">Back to Admin Home</Link>
        <h5>Contact Us Form Responses</h5>

        <Row>
              <Col md={6} className="p-4">
                <p>COMPACT CONTACT - Receptionist</p>
                {messages
                  .filter((msg) => msg.form === "contactus")
                  .map((msg) => (
                    <Msg
                      key={msg.id}
                      {...msg}
                      type="contactus"
                      handleCopy={handleCopy}
                    />
                  ))}
              </Col>
              <Col className="p-4">
                <p>COMPACARE CONTACT</p>
                {messages
                  .filter((msg) => msg.form === "compacare")
                  .map((msg) => (
                    <Msg
                      key={msg.id}
                      {...msg}
                      type="compacare"
                      handleCopy={handleCopy}
                    />
                  ))}
              </Col>
            </Row>

        {messages.length < 1 && (
          <Alert>
            <h5>No messages currently from contact us form</h5>
          </Alert>
        )}
      </Container>
    </>
  );
}

function Msg({
  id,
  message,
  name,
  date,
  email,
  onCopy,
  onDelete,
  copied,
  church_name,
  lead_pastor,
  church_address,
  type,
  phone,
  handleCopy,
}) {
  const deleteMsg = async (e) => {
    e.preventDefault();
    await deleteDoc(doc(db, "Contact", id));
  };

  return (
    <div key={id} className="d-flex flex-column align-items-center">
      <Card style={{ width: "30rem" }} className="border border-3 p-3 my-2">
        <p>
          <small>{date.toDate().toDateString()}</small>
        </p>
        <h5>{name}</h5>
        <a href={`mailto:${email}`}>{email}</a>
        <p>{message}</p>
        {type === "compacare" && (
          <>
            <p>Phone: {phone}</p>
            <p>Church Name: {church_name}</p>
            <p>Pastor Name: {lead_pastor}</p>
            <p>Church Address: {church_address}</p>
          </>
        )}

        <div className="d-flex justify-content-evenly">
          <CopyToClipboard
            text={
              type === "contactus"
                ? `${name}\n${email}\n${date
                    .toDate()
                    .toLocaleDateString("en-US", {
                      dateStyle: "long",
                    })}\n${message}`
                : `Date: ${date
                    .toDate()
                    .toLocaleDateString("en-US", {
                      dateStyle: "long",
                    })}\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\nChurch Name: ${church_name}\nPastor Name: ${lead_pastor}\nChurch Address: ${church_address}\nMessage/How did you hear about us: ${message}`
            }
            onCopy={() =>
              handleCopy(
                id,
                type,
                name,
                email,
                date,
                message,
                church_name,
                lead_pastor,
                church_address,
                phone
              )
            }
          >
            <Button>Copy Message</Button>
          </CopyToClipboard>
          {copied && <span style={{ color: "green" }}>Copied!</span>}
          <Button variant="danger" onClick={deleteMsg}>
            <i className="bi bi-file-x"></i> Delete
          </Button>
        </div>
      </Card>
    </div>
  );
}
