import {Link} from "react-router-dom";
import { useState } from "react";
import { Container } from "react-bootstrap";

export default function Fosterform() {
  document.title = "COMPACT Foster Care Info"
  
  const [state, setState] = useState("");
  
  return (
      <>
      <div className="text-center my-3">
        <Link to="/fostercare" className="btn btn-primary btn-sm" role="button">
          
            Back to Foster Care Home
          
        </Link>
      </div>
{state !== "" &&
<>
<a className="btn btn-primary m-3" href="#" onClick={() => setState("")}>reset form location</a>

</>
}


{state === "" &&
<Container className="d-flex flex-column align-items-center justify-content-center" fluid style={{height:"80vh"}}>
Select your state:
<a className="my-3 btn btn-primary" href="#" onClick={() => setState("arkansas")}>Arkansas</a>
<a className="btn btn-primary" href="#" onClick={() => setState("oklahoma")}>Oklahoma</a>
</Container>
}

{state === "arkansas" &&

      <div className="text-center">
        <iframe
          title="1a"
          frameBorder="0"
          width="100%"
          height="1000"
          src="https://arfc.extendedreach.com/85257DEB00162D05/F_INQUIRY?OpenForm&unid=0F75C7F1016FD55985257DE9000D9209&fbclid=IwAR1hFfGWq6645ISuHwlG7eQ1D09uIFFFV4Cko2vitTiuqcK-eoguDVYDWX4"
        ></iframe>
      </div>
}

{state === "oklahoma" &&

      <div className="text-center">
        <iframe
          title="1a"
          frameBorder="0"
          width="100%"
          height="1000"
          src="https://102184okfc.extendedreach.com/85258A98007845E7/F_INQUIRY?OpenForm&unid=85258A98007B59F585257DE9000D9209"
        ></iframe>
      </div>
}


      </>
  );
}
