const AgLogo = () => {
    return(
        <>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
height="100%"
width="100%"
>
  <path d="M150.57 346.85s-14.87-9.14-14.57-24.72 17.94-39.95 19.74-49.43 0-14.56 0-14.56 14.22 12.35 12.42 31c-1.29 13.25-13.47 29.23-17.32 38.71s-.23 19-.23 19zM163.43 363.62s-9.36-5.75-9.2-15.56 11.3-25.16 12.42-31.14 0-9.17 0-9.17 9 7.79 7.83 19.52c-.81 8.34-8.49 18.4-10.9 24.38a18 18 0 00-.15 12z" fill="#bf212d"/>
  <path d="M435.34 230.67h-86.2l29.67 30.23h22.77v70.86a96.52 96.52 0 01-26.26 9.35 157.92 157.92 0 01-33.09 3.23q-22.67 0-41-7.91a88.5 88.5 0 01-30.93-21.76 97.3 97.3 0 01-19.43-32.56 115.49 115.49 0 01-6.87-39.92 113 113 0 016.83-39.39 99.84 99.84 0 0119.43-32.56 90.19 90.19 0 0130.57-22.11q18-8.1 40.29-8.1 21.93 0 39.75 7.55t29.31 20.14L435 141.83q-15.84-15.12-39.75-24.1t-54.5-9a147.1 147.1 0 00-54 9.71 129.32 129.32 0 00-43 27.17 123 123 0 00-28.42 42.08c-1 2.36-1.85 4.78-2.67 7.22l-34.23-79.7h-32.27L32.72 376.36h39.57l28.54-69.06 12.59-31.3 47.85-119.43L208.92 276s12.06 31.76 17.33 40.44a113.87 113.87 0 0017.06 22.34 125.8 125.8 0 0040.9 26.81c.69.29 1.37.61 2.08.88q25 9.9 54.86 9.9a220.57 220.57 0 0051.8-5.75 184.82 184.82 0 0043.89-16.56V230.67zM459 139.73a6.33 6.33 0 002.52-.5 2 2 0 001.2-2 1.82 1.82 0 00-1.1-1.86 5.76 5.76 0 00-2.29-.47h-2.79v4.85zm-5.51-7.43h6a9.94 9.94 0 012.32.26 6.11 6.11 0 012 .86 4.45 4.45 0 011.43 1.53 4.68 4.68 0 01.54 2.32 4.33 4.33 0 01-1.07 3 5.38 5.38 0 01-2.85 1.56l4.58 7.43h-3.72l-4-7.1h-2.19v7.1h-3.05zm-6.84 8.56a12.43 12.43 0 001 4.92 13.49 13.49 0 002.72 4.08 12.62 12.62 0 004.06 2.75 12.71 12.71 0 0014-2.75 13.52 13.52 0 002.73-4.08 12.43 12.43 0 001-4.92 12.58 12.58 0 00-1-5 12.91 12.91 0 00-2.73-4 12.71 12.71 0 00-4.05-2.72 12.9 12.9 0 00-10 0 12.63 12.63 0 00-6.78 6.77 12.58 12.58 0 00-1 5zm-2.39 0a14.75 14.75 0 011.19-5.88 15 15 0 013.26-4.78 15.8 15.8 0 014.81-3.22 15 15 0 0111.76 0 15.8 15.8 0 014.81 3.22 15.19 15.19 0 013.26 4.78 14.75 14.75 0 011.19 5.88 14.45 14.45 0 01-1.19 5.84 15.17 15.17 0 01-3.26 4.75 15.36 15.36 0 01-4.81 3.19 15.48 15.48 0 01-11.76 0 15.36 15.36 0 01-4.81-3.19 15 15 0 01-3.26-4.75 14.45 14.45 0 01-1.19-5.84z" fill="#231f20"/>
</svg>
        </>

    )
} 

export default AgLogo;