const JobApplyButton = () => {
    return(
        <>
         
      <p align="center">
    
    <a
      href="https://form.jotform.com/hrcompact/employment-application-form"
      target="_blank"
      className="btn btn-dark btn-lg"
      role="button"
      rel="noopener noreferrer"
      >
      Apply Online Here
    </a>
  </p>
    
        </>
    )
}

export default JobApplyButton;