// import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import {CgProfile} from "react-icons/cg"
import { Link } from "react-router-dom";

import "../compact.css";

export const SocialLineup = () => {

  return (
    <div className="p-1">
      <a
        className="SocialStyle"
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/COMPACTFamilyServices/"
      >
        <FaFacebook />
      </a>

      <a
        rel="noopener noreferrer"
        className="SocialStyle ms-1 me-1"
        target="_blank"
        href="https://www.instagram.com/compactfamilyservices/"
      >
        <FaInstagram />
      </a>

      <a
        rel="noopener noreferrer"
        className="SocialStyle me-1"
        target="_blank"
        href="https://twitter.com/COMPACTfamily"
      >
        {/* <FaXTwitter /> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
</svg>
      </a>

      <a
        rel="noopener noreferrer"
        className="SocialStyle me-3"
        target="_blank"
        href="https://www.youtube.com/channel/UC7LHU70Uou3e8DsrNj6CnNA/"
      >
        <FaYoutube />
      </a>
      <Link to="/dashboard">
     <CgProfile />
      </Link>


     
    </div>
  );
};
export default SocialLineup;

//   https://stackoverflow.com/questions/57200956/add-link-to-font-awesome-icon-in-reactjs

// fix out links and style
